/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

ion-card-title{
    background-color: #f4f5f8;
    padding: 5px;
    border-radius: 8px;
}


.online {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background: green;
    display: inline-block;
    border: solid 1px #ffffff;
    margin: 0 3px;
}

.offline {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background: red;
    display: inline-block;
    border: solid 1px #ffffff;
    margin: 0 3px;
}
.text-red-b{
    color: red;
    font-weight: bold;
}

.text-b{
    font-weight: bold;
}

.text-red{
    color: red;
}

.text-green{
    color: green;
}

.table{
    width: 100%;
    border-collapse: collapse;
    box-sizing: border-box;
    display: table;
    text-indent: initial;
    border-spacing: 2px;
    border-color: grey;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: right;
}
.table tr{
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
}
.table td, .table th{
    padding: .75rem;
    vertical-align: top;
    border: 1px solid #dee2e6;
}
.thead {
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;
}

.tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
}